import I18n from 'i18n-js';
import TaskAssignation from '../utils/TaskAssignation';
import ActionAssignation from '../utils/ActionAssignation';

export default class BackofficeTasksShow {
  constructor() {
    this.bindEvents();
    this.initDropify();
    this.taskAssignation = new TaskAssignation();
  }

  bindEvents() {
    this.actionAssignation = new ActionAssignation();
    
    const self = this;

    const sp = new URLSearchParams(window.location.search).get('sp');
    if (sp) {
      $(window).scrollTop(sp);
    }

    $(".show-comments").on('click', this.showComments);
    $(".show-files").on('click', this.showFiles);

    $(".comment-visibility-checkbox").on('change', this.changeItemVisibility);

    $(".show-file-btn").on('click', this.showFileModal);

    // Move forward event handler
    $(".move-forward").on('click', function() {
      self.handleStateUpdate($(this), 'bg-success text-white', 'bg-warning text-white', ".next-step-alert", ".previous-step-alert", 'url-forward');
    });

    // Move backward event handler
    $(".move-backward").on('click', function() {
      self.handleStateUpdate($(this), 'bg-warning text-white', 'bg-success text-white', ".previous-step-alert", ".next-step-alert", 'url-backward');
    });

    // Original item click event handler
    $(".original").on('click', function() {
      var $li = $(this);
      $li.addClass('bg-info text-white').removeClass('bg-soft-success bg-soft-warning')
         .siblings(".aside").removeClass('bg-warning bg-success text-white');
      
      $(".next-step-alert").addClass("d-none");
      $(".previous-step-alert").addClass("d-none");

      var $updateBtn = $(".update-process-btn");
      self.updateButtonState($updateBtn, "#", true);
    });

    $(".owners-select").each(function() {
      $(this).select2({
        placeholder: "Choisir un ou plusieurs propriétaires",
        allowClear: true,
        closeOnSelect: false,
        multiple: true
      });
    });

    self.updateActionTime(".started-at-field", "started_at", "backoffice.actions.update_started_at_success", "backoffice.actions.update_started_at_error");
    self.updateActionTime(".ended-at-field", "ended_at", "backoffice.actions.update_ended_at_success", "backoffice.actions.update_ended_at_error");

    $(".status-select").off('change').on('change', self.bindStatusChange);

    self.bindStatusChange();
    self.bindCommentButton();
    self.bindCommentSubmit();

    $(".custom-field-submit").on('click', function() {
      const field_id = $(this).data('field-id');      
      const field = $(`#${field_id}`);

      const url = $(field).data('url');

      // check that the field complete all validations
      if (field[0].checkValidity() === false) {
        field.addClass('is-invalid');
        $(`#${field_id}_validation`).removeClass('text-muted').addClass('text-danger').text(field[0].validationMessage);
        return;
      }

      $.ajax({
        url: url,
        method: 'PUT',
        data: { value: field.val() },
        success: function(data) {
          $(`#${field_id}_validation`).removeClass('text-danger').addClass('text-muted').text('');
          field.removeClass('is-invalid');
          application.successNotification(I18n.t('backoffice.tasks.custom_field_updated'));
        },
        error: function(data) {
          application.errorNotification(I18n.t('backoffice.tasks.custom_field_update_error'));
        }
      });
    });
  }

  bindStatusChange() {
    const status = $(this).val();
    const url = $(this).data('url');
  
    $.ajax({
      url: url,
      method: 'PUT',
      data: { status: status, sp: $(window).scrollTop() },
      success: function(data) {
        $("#steps-wrapper").html(data.steps);
        self.bindEvents();
      }
    });
  }

  handleStateUpdate($li, addClass, removeClass, alertToShow, alertToHide, urlData) {
    const self = this;
    
    $li.addClass(addClass).siblings(".aside").removeClass(removeClass);
    
    var $ogLi = $li.siblings(".original");
    $ogLi.removeClass('bg-info text-white bg-soft-warning bg-soft-success').addClass(addClass.replace('bg-', 'bg-soft-'));
  
    $(alertToHide).addClass("d-none");
    $(alertToShow).removeClass("d-none");
  
    var $updateBtn = $(".update-process-btn");
    
    if ($li.data('step') == 1) {
      self.updateButtonState($updateBtn, "#", true);

      $updateBtn.off('click').on('click', function(event) {
        event.preventDefault();
        $('#order-edit').modal('show');
        $('#order-process').modal('hide');
      }).removeClass('disabled').removeAttr('data-method');
    } else {
      self.updateButtonState($updateBtn, $updateBtn.data(urlData));
      if (!$updateBtn.attr('data-method')) {
        $updateBtn.attr('data-method', 'put');
      }
      $updateBtn.off('click');
    }
  }
  
  updateButtonState(updateBtn, url, disabled = false) {
      updateBtn.toggleClass('disabled', disabled);
      updateBtn.attr('href', url);
  }

  showComments() {
    $("#comments-section").removeClass("d-none").addClass("d-flex");
    $("#files-section").removeClass("d-flex").addClass("d-none");

    $(".show-comments").addClass("underline");
    $(".show-files").removeClass("underline")
  }

  showFiles() {
    $("#comments-section").removeClass("d-flex").addClass("d-none");
    $("#files-section").removeClass("d-none").addClass("d-flex");

    $(".show-comments").removeClass("underline")
    $(".show-files").addClass("underline")
  }

  showFileModal() {
    let title = $(this).data('file-title');
    let url = $(this).data('file-url');

    $("#file-modal-title").text(title);
    
    $("#embed-wrapper").empty();
    if (url.endsWith('.pdf')) {
      $("#embed-wrapper").append(`<embed src="${url}" type="application/pdf" width="100%" height="600px" />`);
    } else if (url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png')) {
      $("#embed-wrapper").append(`<img src="${url}" class="img-fluid" />`);
    } else {
      $("#embed-wrapper").append(`<iframe src="${url}" width="100%" height="600px" />`);
    }

    $("#download-button").attr('href', url);
    $("#file-modal").modal('show'); 
  }

  changeItemVisibility() {
    const attachment_id = $(this).data('attachment-id');
    const item_type = $(this).data('item-type');
    const visibility = $(this).is(':checked');
    const url = $(this).data('url');

    $.ajax({
      url: url,
      method: 'PUT',
      data: { 
        item: {
          visibility: visibility,
          item_type: item_type,
          id: attachment_id
        }
      },
      success: function(data) {
        application.successNotification(I18n.t("backoffice.tasks.update_visibility_success"))
      },
      error: function(data) {
        application.errorNotification(I18n.t('backoffice.tasks.update_visibility_error'));
      }
    });
  }

  initDropify() {
    $(".dropify").dropify({
      messages: {
      'default': I18n.t('plugins.dropify.messages.default'),
      'replace': I18n.t('plugins.dropify.messages.replace'),
      'remove': I18n.t('plugins.dropify.messages.remove'),
      'error': I18n.t('plugins.dropify.messages.error')
      },
      error: {
      'fileSize': I18n.t('plugins.dropify.error.fileSize', { value: '2MB' }),
      'minWidth': I18n.t('plugins.dropify.error.minWidth'),
      'maxWidth': I18n.t('plugins.dropify.error.maxWidth'),
      'minHeight': I18n.t('plugins.dropify.error.minHeight'),
      'maxHeight': I18n.t('plugins.dropify.error.maxHeight'),
      'imageFormat': I18n.t('plugins.dropify.error.imageFormat'),
      'fileExtension': I18n.t('plugins.dropify.error.fileExtension'),
    }
    });
  }

  updateActionTime(fieldClass, timeKey, successMessage, errorMessage) {
    $(fieldClass).on('change', function() {
      const timeValue = $(this).val();
      const url = $(this).data('url');
      
      // Regular expression to validate full date (YYYY-MM-DD)
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      const minDate = new Date('2024-01-01');

      if (dateRegex.test(timeValue)) {
        const inputDate = new Date(timeValue);
        if (inputDate > minDate) {
          $.ajax({
            url: url,
            method: 'PUT',
            data: { task: { [timeKey]: timeValue } },
            success: function(data) {
              application.successNotification(I18n.t(successMessage));

              $(`#action-${data.action_id}-deadline`).html(data.deadline);
              if (data.is_late) {
                const element = $(`.ended-at-field-${data.action_id}`);
                
                console.log(element);
                
                element.addClass('is-late');
                element.trigger('change');
              } else {
                $(`.ended-at-field-${data.action_id}`).removeClass('is-late');
              }
            },
            error: function(data) {
              application.errorNotification(I18n.t(errorMessage));
            }
          });
        }
      }
    });
  }

  bindStatusChange() {
    const self = this;

    $(".status-select").on('change', function() {
      const selectObj = $(this);
      const status = selectObj.val();
      const url = selectObj.data('url');
  
      $.ajax({
        url: url,
        method: 'PUT',
        data: { status: status, sp: $(window).scrollTop() },
        success: function(data) {
          $("#steps-wrapper").html(data.steps);
          self.bindEvents();
        },
        error: function() {
          application.errorNotification(I18n.t('backoffice.tasks.update_status_error'));
        }
      });
    });
  }

  bindCommentSubmit() {
    const self = this;
  
    $(".action-comment-submit").on('click', function() {  
      const form = $(this).closest('form')[0];
      const url = $(form).attr('action');
      const formData = new FormData(form);
  
      $.ajax({
        url: url,
        method: 'POST',
        data: formData,
        contentType: false,
        processData: false,
        success: function(data) {
          $(".action-comments-modal-body").html(data.content);
          application.successNotification(I18n.t('backoffice.tasks.comments.comment_added'));
          
          var action_id = data.action_id;

          const commentCounter = $(`#comments-count-action-${action_id}`);
          
          if (data.comments_count) {
            commentCounter.text(data.comments_count);
          }

          self.initDropify(); 
          self.bindCommentSubmit();
          self.bindActionCommentsScrollToTop();
        },
        error: function(data) {
          $(".action-comments-modal-body").html(data.content);
          application.errorNotification(I18n.t('backoffice.tasks.comments.comment_not_added'));

          self.initDropify(); 
          self.bindCommentSubmit();
          self.bindActionCommentsScrollToTop();
        }
      });
    });
  }
  
  bindCommentButton() {
    const self = this;

    $(".action-comments-btn").on('click', function() {
      const url = $(this).data('url');
  
      $.ajax({
        url: url,
        method: 'GET',
        success: function(data) {
          $(".action-comments-modal-body").html(data);
         
          $('.action-comments-wrapper').scrollTop($('.action-comments-wrapper')[0].scrollHeight);

          $("#actionComments").modal('show');
          self.initDropify(); 
          self.bindCommentSubmit();
          
          $("#actionComments").on('shown.bs.modal', function() {
            self.bindActionCommentsScrollToTop();
          });
        }
      });
    });
  }

  bindActionCommentsScrollToTop() {
      var commentsWrapper = $('.action-comments-wrapper');
      var scrollToTopBtn = $('#scroll-to-top-btn');
  
      // Function to update the visibility of the scroll-to-top button
      function updateScrollToTopBtnVisibility() {
          if (commentsWrapper.scrollTop() === 0) {
              scrollToTopBtn.hide();
          } else {
              scrollToTopBtn.show();
          }
      }
  
      // Initial check
      updateScrollToTopBtnVisibility();
  
      // Add scroll event listener to commentsWrapper
      commentsWrapper.on('scroll', updateScrollToTopBtnVisibility);
  
      // Add click event listener to scrollToTopBtn
      scrollToTopBtn.on('click', function() {
          commentsWrapper.animate({ scrollTop: 0 }, 'slow');
      });
  }

  onDataChanged(data) {
  }

  onDestroy() {
    $(".dropify").dropify("destroy");
  }
}