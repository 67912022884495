export default class BackofficeWorkflowsEditNotifications {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    this.toggleVisibility('#workflowNotifications', '#steps');

    $('.step-checkbox').each((index, checkbox) => {
      const stepIndex = $(checkbox).data('step');
      this.toggleVisibility(checkbox, `.step${stepIndex}-subactions`);
    });

    $('#workflowNotifications').change((event) => {
      this.toggleVisibility(event.target, '#steps');
    });

    $('.step-checkbox').change((event) => {
      const stepIndex = $(event.target).data('step');
      const subactions = $(`.step${stepIndex}-subactions input[type="checkbox"]`);

      this.toggleVisibility(event.target, `.step${stepIndex}-subactions`);

      subactions.prop('checked', $(event.target).is(':checked'));
    });

    $('input[type="checkbox"]').each((index, checkbox) => {
      if (!$(checkbox).is(':checked')) {
        $('<input>').attr({
          type: 'hidden',
          name: $(checkbox).attr('name'),
          value: '0'
        }).insertBefore($(checkbox));
      }
    });

    $('input[type="checkbox"]').change((event) => {
      if ($(event.target).is(':checked')) {
        $(event.target).prev('input[type="hidden"]').remove();
      } else {
        $('<input>').attr({
          type: 'hidden',
          name: $(event.target).attr('name'),
          value: '0'
        }).insertBefore($(event.target));
      }
    });
  }

  toggleVisibility(checkbox, target) {
    if ($(checkbox).is(':checked')) {
      $(target).show();
    } else {
      $(target).hide();
    }
  }

  onDataChanged(data) {
    // Handle data changes if needed
  }

  onDestroy() {
    // Clean up if needed
  }
}